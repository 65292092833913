import { useEffect, useRef, useState } from "react";
import "./styles.css";

export default function App() {
  const [e, setLog] = useState({ x: 0, y: 0, type: undefined });
  let drop100Events = 0;
  const onMouseMoveHandler = (e) => {
    // if (drop100Events < 1) {
    //   drop100Events++;
    //   return;
    // }
    // if (e.type === " " || e.type === "pointerrawupdate")
    setLog({ x: "", y: "", type: e.type });
    // console.log(e);
    // drop100Events = 0;
  };

  const collectAllRef = useRef(null);

  const checkBrowser = async () => {
    setLog({ x: navigator.userAgent, y: "", type: "" });
  };

  let posY = 0;
  const oneStep = 250;
  const doubleStep = 700;
  useEffect(() => {
    setTimeout(() => {
      let scrollTopX = document.getElementById("scrollTopX"),
        scrollTopOne = document.getElementById("scrollTopOne"),
        scrollBottomX = document.getElementById("scrollBottomX"),
        scrollBottomOne = document.getElementById("scrollBottomOne");
      // console.log("overall height",$(document).height());
      const max_height = document.body.clientHeight - window.innerHeight;
      // alert(max_height);
      if (scrollTopX)
        scrollTopX.addEventListener("click", function () {
          posY -= oneStep;
          if(posY<0) posY = 0;

          window.scrollTo({ left: 0, top: posY, behavior: "smooth" });
        });
      if (scrollTopOne)
        scrollTopOne.addEventListener("click", function () {
          posY -= doubleStep;
          if(posY<0) posY = 0;
          window.scrollTo({ left: 0, top: posY, behavior: "smooth" });
        });
      if (scrollBottomX)
        scrollBottomX.addEventListener("click", function () {
          posY += oneStep;
          if(posY>max_height) posY = max_height;
          window.scrollTo({ left: 0, top: posY, behavior: "smooth" });
        });
      if (scrollBottomOne)
        scrollBottomOne.addEventListener("click", function () {
          posY += doubleStep;
          if(posY>max_height) posY = max_height;
          window.scrollTo({ left: 0, top: posY, behavior: "smooth" });
        });
    }, 1000);

    const maxNumOfSection = 20;
    const rootContainer = document.getElementById("rootContainer");
    const sectionList = [];

    for (let i = 0; i < maxNumOfSection; i++) {
      const section = document.createElement("section");
      const h1 = document.createElement("h3");
      h1.innerText = "#" + (i + 1);
      section.appendChild(h1);
      sectionList.push(section);
      rootContainer.appendChild(section);

      // <section>
      //  <h3>
      //  #1~20
      //  </h3>

      // </section>
    }

    const results = {};

    const randomSequence = [
      1, 15, 2, 8, 4, 19, 4, 19, 11, 2, 8, 13, 11, 9, 6, 7, 15, 1, 19,
    ];

    let seqNum = 0;
    let currentMessage = null;
    let prevMessage = null;

    const timer = {
      cur: 0,
      prev: 0,
      started: false,

      start() {
        if (this.started) return;
        this.started = true;
        this.prev = new Date();
      },
      end() {
        if (!this.started) return;
        this.started = false;
        this.cur = new Date();
      },

      getMilliSeconds() {
        if (this.started) return 0;
        return this.cur - this.prev;
      },
    };

    const getNextTarget = () => {
      if (prevMessage != null) prevMessage.remove();
      prevMessage = currentMessage;
      prevMessage.removeEventListener("click", cilckEvent);
      prevMessage.innerText = "go to " + (randomSequence[seqNum + 1] + 1);
      seqNum++;
      generateTarget(sectionList[randomSequence[seqNum]]);
      timer.start();
    };

    const getResults = () => {
      return JSON.stringify(results);
    };

    const cilckEvent = () => {
      timer.end();
      const result = {
        seqNum: seqNum,
        sectionDistance: Math.abs(
          randomSequence[seqNum - 1] - randomSequence[seqNum]
        ),
        time: timer.getMilliSeconds(),
      };
      results["trial" + seqNum] = result;
      if (currentMessage.innerText == "finish experiment") finishExperiment();
      else getNextTarget();
    };

    const finishExperiment = () => {
      alert("finished");
      console.log(rootContainer.children);
      const resultElement = document.createElement("textarea");
      resultElement.innerText = getResults();
      rootContainer.innerHTML = "";
      rootContainer.appendChild(resultElement);

      resultElement.addEventListener("click", () => {
        resultElement.select();
        document.execCommand("copy");
        alert("copied to clipboard!");
      });
    };

    const generateTarget = (section) => {
      const message = document.createElement("button");
      message.className = "message";
      if (seqNum + 1 < randomSequence.length) message.innerText = "click this!";
      else message.innerText = "finish experiment";

      message.addEventListener("click", cilckEvent);

      section.appendChild(message);
      currentMessage = message;
    };

    generateTarget(sectionList[randomSequence[seqNum]]);
    // for (var key in collectAllRef.current) {
    //   if (key.search("on") === 0) {
    //     console.log(key);
    //     collectAllRef.current.addEventListener(
    //       key.slice(2),
    //       onMouseMoveHandler
    //     );
    //   }
    // }
    // setTimeout(checkBrowser, 1000);
  }, []);

  const scrollTo = (px) => {
    const target = window.pageYOffset + px;
    window.scroll({
      top: target,
      behavior: "smooth",
    });
  };

  const handleScrollUp = (scrollConstant) => {
    scrollTo(-scrollConstant);
  };
  const handleScrollDown = (scrollConstant) => {
    scrollTo(scrollConstant);
  };

  return (
    <div className="App">
      <div id="App">
        <button id="scrollTopX" className="scrollAssistant"></button>
        <button id="scrollTopOne" className="scrollAssistant"></button>
        <button id="scrollBottomX" className="scrollAssistant"></button>
        <button id="scrollBottomOne" className="scrollAssistant"></button>
      </div>
      <div id="rootContainer"></div>
    </div>
  );
}

const lorem = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur interdum tincidunt nisi at pretium. Aliquam eget faucibus nisl. Nam pretium risus diam. Sed non vulputate ante. Pellentesque tincidunt erat sed ipsum laoreet tristique. Duis felis est, sollicitudin eget maximus sed, sagittis ac massa. Nullam at urna eget leo feugiat tincidunt vel at odio. Donec id nulla ac erat sollicitudin consequat a non lacus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed sodales ipsum, eget imperdiet diam. Praesent aliquet dapibus odio, vel maximus lacus varius ut. Praesent aliquet justo sit amet tincidunt maximus.
Etiam dictum odio sit amet elit rhoncus varius. Quisque dictum nunc ac iaculis ullamcorper. Nam vel vulputate metus. Aenean non dictum turpis. Duis ut cursus magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras eget libero nisl. Pellentesque dignissim libero id accumsan eleifend. Morbi laoreet enim eget ante venenatis, mollis fringilla turpis viverra. Ut hendrerit dapibus dui sagittis commodo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce massa sem, aliquam eu magna in, ultrices accumsan dui. Sed accumsan tincidunt libero sit amet malesuada.
`;
const ingredients_greekSalad = ` Tomato , Feta , Cucumber , Onion , Olive , Papper , Lettuce , Olive oil , Vinegar `;
